<template>
  <el-dialog :visible.sync="isShow" custom-class="custom-dialog">
    <div class="real-name">
      <div class="real-name-title">{{ $t("身份验证") }}</div>
      <el-form label-positon="top" class="real-name-form">
        <div v-for="item in initFormList(data)" :key="item.name">
          <div class="introduce html" v-if="$t(item[0]?.introduce)" v-html="$t(item[0]?.introduce)"></div>
          <el-form-item :label="item.introduce ? `${ $t(item.name) } (${ item.introduce })` : $t(item.name)" v-if="item.type === 'text' || item.type === 'date'  || item.type === 'select'">
            <!-- 文本 -->
            <el-input v-if="item.type === 'text'" :placeholder="$t('请输入' + item.name)" v-model="item.value" :disabled="userInfo.user_verify_status == 2 || userInfo.user_verify_status == 1"></el-input>

            <!-- 下拉 -->
            <el-select style="width: 100%;" v-if="item.type === 'select'" v-model="item.value" :placeholder="$t('请选择')" :disabled="userInfo.user_verify_status == 2 || userInfo.user_verify_status == 1">
              <el-option v-for="item in item.options" :key="item" :label="$t(item)" :value="$t(item)"></el-option>
            </el-select>

            <!-- 日期 -->
            <el-date-picker v-if="item.type === 'date'" style="width: 100%;" format="MM-dd-yyyy" value-format="yyyy-MM-dd" v-model="item.value" :placeholder="$t('请选择')"  popper-class="el-date-picker-content" class="date-picker" :disabled="userInfo.user_verify_status == 2 || userInfo.user_verify_status == 1"></el-date-picker>

          </el-form-item>
          <div v-else class="uploader-list">
            <el-upload class="uploader" :action="action" :show-file-list="false"
              :on-change="(e) => change(e, imgItem.key)" :disabled="loadings[imgItem.key] || !isUpload"
              :on-success="(e) => uploadSuccess(e, imgItem.key)" :headers="uploadHeader" v-for="imgItem,index in item" :key="index">
              <div class="card-warp" v-loading="loadings[imgItem.key]" >
                <div class="card-content">
                  <img :src="findData(imgItem.key)?.value" class="upload-img" v-if="findData(imgItem.key)?.value"
                    key="upload-img">
                  <img src="@/assets/images/camera.png" class="upload-icon"  key="upload-icon" v-else>
                </div>
              </div>
              <span class="upload-tip">{{ $t(imgItem.name) }}</span>
            </el-upload>
          </div>
        </div>

      </el-form>

      <div class="status" v-if="userInfo.user_verify_status == 2">
        <img src="@/assets/images/id-icon.png" alt="" class="id-icon">
        <span>{{ $t("已认证") }}</span>
      </div>
      <div class="status status-yellow" v-if="userInfo.user_verify_status == 1">
        <img src="@/assets/images/id-icon-yellow.png" alt="" class="id-icon">
        <span>{{ $t("正在审核中，请耐心等待") }}</span>
      </div>
      <div class="status status-red" v-if="userInfo.user_verify_status == 3">
        <img src="@/assets/images/id-icon-red.png" alt="" class="id-icon">
        <span>
          {{ $t("审核失败，重新认证") }} <br>
          {{ verify_data.remarks }}
        </span>
      </div>
      <el-button class="button_blue button_fullwidth" @click="submit" v-if="isUpload" :loading="btnLoading">{{
        $t("立即认证") }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { user_add_verify_api, user_verify_vonfig_api } from "@/api/user"
export default {
  data() {
    return {
      isShow: false,
      uploadHeader: { usertoken: "", usertokenkey: "" },
      action: this.$helper.getApiUrl() + '/upload/img',
      btnLoading: false,
      loadings: {},
      data: [],
      verify_data:{},
    }
  },
  computed: {
    // 是否能够上传
    isUpload() {
      return this.userInfo.user_verify_status == 0 || this.userInfo.user_verify_status == 3
    },
    userInfo() {
      return this.$store.state.userInfo || {}
    }
  },
  methods: {
    async show() {
      let { token, token_key } = this.$helper.get("tokenInfo");
      this.uploadHeader.usertoken = token;
      this.uploadHeader.usertokenkey = token_key;
      await this.getData()
      this.isShow = true;
    },
    async getData() {
      const { data: res } = await user_verify_vonfig_api()
      if (res.code == 1) {
        this.data = res.data
        this.verify_data = res.verify_data
      }
    },

    initFormList(list){
      const arr = []
      list.forEach(item=>{
        if(item.key==="Sex"){
          item.type = "select"
          item.options = ["男","女"]
        }
        if(item.key==="Birthday"){
          item.type = "date"
        }
        if(item.type==='img'){
          const lastData = arr[arr.length-1]
          if(lastData.length === 1){
            lastData.push(item)
          }else{
            arr.push([item])
          }
        }else{
          arr.push(item)
        }
      })
      return arr
    },

    async submit() {
      this.btnLoading = true
      const { data: res } = await user_add_verify_api({ content: JSON.stringify(this.data) })
      this.btnLoading = false
      if (res.code == 1) {
        this.$message({
          showClose: true,
          message: this.$t("提交成功，等待后台审核"),
          type: "success",
        });
        // 更新状态
        this.$store.commit("$vuexUserInfo", {
          ...this.userInfo,
          user_verify_status: 1
        })
        this.isShow = false
      } else {
        this.errorTips(res.msg)
      }
    },
    findData(key) {
      return this.data.find(item => item.key === key)
    },
    change(e, key) {
      this.loadings[key] = (e.status !== 'success')
      this.$forceUpdate();
    },
    uploadSuccess(e, key) {
      if (e.code == 1) {
        this.findData(key).value = e.data.file;
        this.$forceUpdate();
      }
    },
  }
}
</script>

<style scoped lang="less" src="@/assets/css/realNameAuthentication.less"></style>